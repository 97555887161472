<template>
  <div id="app">
    <h1>Procesador de archivos de asistencia</h1>
    <form @submit.prevent="processFile">
      <div>
        <label for="monthYear">Mes y Año (MMAAAA):</label>
        <input type="text" v-model="monthYear" id="monthYear" required />
      </div>
      <div>
        <label for="file">Seleccionar archivo:</label>
        <input type="file" @change="handleFileUpload" id="file" required />
      </div>
      <div>
        <label>Formato:</label>
        <input type="radio" id="utf8" value="utf-8" v-model="decodingFormat" />
        <label for="utf8">UTF-8</label>
        <input type="radio" id="utf16le" value="utf-16-le" v-model="decodingFormat" />
        <label for="utf16le">UTF-16-LE</label>
      </div>
      <button type="submit">Procesar</button>
    </form>
    <div v-if="csvContent">
      <h2>CSV procesado</h2>
      <textarea v-model="csvContent" rows="20" cols="80" readonly></textarea>
      <div>
        <button @click="copyToClipboard">Copiar todo el contenido</button>
        <button @click="downloadCSV">Descargar CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      monthYear: '',
      file: null,
      csvContent: null,
      decodingFormat: 'utf-8'
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async processFile() {
      if (!this.file || !this.monthYear) {
        alert('Por favor, provee fecha y archivo.');
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64File = btoa(e.target.result);
        try {
          let config = {
            method: 'post',
            url: `https://jnzjpf4yah.execute-api.us-east-2.amazonaws.com/default/utf8-processer?month_year=${this.monthYear}&decoding=${this.decodingFormat}`,
            headers: { 
              'Content-Type': 'text/plain'
            },
            data: base64File
          };
          let response = await axios(config);
          this.csvContent = response.data;
        } catch (error) {
          console.error(error);
          alert('Ocurrio un error al procesar el archivo.');
        }
      };
      reader.readAsBinaryString(this.file);
    },
    copyToClipboard() {
      const textarea = document.createElement('textarea');
      textarea.value = this.csvContent;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      alert('Contenido copiado al portapapeles.');
    },
    downloadCSV() {
      const blob = new Blob([this.csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `processed_${this.monthYear}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style>
#app {
  max-width: 600px;
  margin: 50px auto;
  text-align: center;
}

form {
  margin-bottom: 20px;
}

form div {
  margin-bottom: 10px;
}

h2 {
  margin-top: 20px;
}

textarea {
  width: 100%;
  white-space: pre;
}

button {
  margin-right: 10px;
}
</style>
